import ANALYTICS_ACTIONS from '../../../../lib/constants/ANALYTICS_ACTIONS'

export function CustomerAnalytics({
  customer = {},
}: {
  customer?: { id?: number; email?: string } | null
}) {
  return (
    <>
      {customer?.id && (
        <input type="hidden" data-cw-track={ANALYTICS_ACTIONS.customerId} value={customer.id} />
      )}
      {customer?.email && (
        <input
          type="hidden"
          data-cw-track={ANALYTICS_ACTIONS.customerEmail}
          value={customer.email}
        />
      )}
    </>
  )
}
