import useCustomerHasAutoshipSubscriptions from '../../../hooks/useCustomerHasAutoshipSubscriptions'
import AUTOSHIP from '../../../lib/constants/AUTOSHIP'
import SHIPPING_METHOD from '../../../lib/constants/SHIPPING_METHOD'
import { featureFlags } from '../../../lib/publicConfig'
import StandardIcon from '../../global/icons/StandardIcon'
import AB from '../ab-testing/AB'
import { useUI } from '../ui-context'

export function SiteBanner() {
  const { openAutoshipModal, openHolidayShippingModal } = useUI()
  const customerHasAutoshipSubscriptions = useCustomerHasAutoshipSubscriptions()

  return (
    <header className="h-[2.85rem] overflow-hidden bg-white py-1 text-center text-base sm:h-[2.625rem] md:h-[2.5rem] md:py-1.5 md:text-[1.375rem]">
      {featureFlags.displayPromoAlert ? (
        <p className="p-1 text-sm leading-snug text-plum md:text-xl">
          New Year, New Savings. Save 15% Off Sitewide with Code: <strong>NY2023</strong>. Limited
          time only!
        </p>
      ) : featureFlags.displayShippingDelayAlert ? (
        <button
          type="button"
          className="w-full space-x-2 p-1 text-secondaryBeta"
          onClick={openHolidayShippingModal}
        >
          <span className="inline-block align-middle md:hidden">
            <StandardIcon icon="truck" alt="" width={20} height={20} />
          </span>
          <span className="hidden align-middle md:inline-block">
            <StandardIcon icon="truck" alt="" width={24} height={24} />
          </span>
          <span className="font-semibold">Holiday shipping may impact delivery times.</span>
          <span className="whitespace-nowrap text-sm text-blue underline md:text-lg">
            Learn More
          </span>
        </button>
      ) : !customerHasAutoshipSubscriptions ? (
        <>
          <AB name="autoship-banner-language" value="a">
            <button type="button" className="w-full p-1" onClick={openAutoshipModal}>
              <span className="font-bold text-secondaryBeta">
                Save {AUTOSHIP.promotions.first.discount.percentage}%
              </span>
              <span>&nbsp;on your first</span>
              <div className="inline align-middle">
                &nbsp;
                <StandardIcon icon="autoship" alt="" width={20} height={20} />
              </div>
              <span className="font-semibold text-primaryBeta">&nbsp;Autoship* </span>
              <span className="whitespace-nowrap text-sm text-blue underline md:text-lg">
                Learn More
              </span>
            </button>
          </AB>
          <AB name="autoship-banner-language" value="b">
            <button type="button" className="w-full p-1" onClick={openAutoshipModal}>
              <span>Join thousands of others on</span>
              <span className="font-semibold text-primaryBeta">&nbsp;Autoship* </span>
              <span className="whitespace-nowrap text-sm text-blue underline md:text-lg">
                Learn More
              </span>
            </button>
          </AB>
        </>
      ) : (
        <p className="p-1">
          <span className="font-bold text-secondaryBeta">
            {SHIPPING_METHOD.promotional.label} Shipping
          </span>
          &nbsp;on Orders ${SHIPPING_METHOD.promotional.threshold}&#43;
        </p>
      )}
    </header>
  )
}
