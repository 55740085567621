import type { UrlObject } from 'url'
import Image from 'next/legacy/image'
import Link from 'next/link'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import type {
  SearchSpringSuggestData,
  Suggestion,
} from '../../../hooks/pageType/category/useSearchspringSuggest'
import type { UseHeaderSearchSpringQuery } from '../../../hooks/useHeaderSearchSpringQuery'
import { useHoverSelect } from '../../../hooks/useHoverSelect'
import routes from '../../../lib/routes'
import { formatStringToCurrency } from '../../../lib/utils/currency'
import { getPathStructureForProduct } from '../../../lib/utils/pathStructure'
import range from '../../../lib/utils/range'
import unescape from '../../../lib/utils/unescape'
import { LegacyChevronRightIcon } from '../legacy/icons/LegacyChevronRightIcon'

function SuggestionAnchor({
  suggestion,
  onSelect,
  isSelected,
  tabIndex,
  onClick,
}: {
  suggestion: Suggestion
  onSelect: (text?: string) => void
  isSelected: boolean
  tabIndex: number
  onClick: () => void
}) {
  const { onMouseEnter, onMouseLeave } = useHoverSelect(() => {
    onSelect(suggestion.text)
  })

  return (
    <Link
      href={{
        pathname: '/search',
        query: {
          search_query_adv: suggestion.text,
        },
      }}
      tabIndex={tabIndex}
      className={`Suggestion block px-3 py-2 font-bold ${
        isSelected ? `bg-ds-neutral underline` : ''
      } focus:underline focus:outline-none`}
      onClick={() => {
        onClick()
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onKeyDown={(e) => {
        const thisListElem = e.target as HTMLLIElement

        if (e.key === 'ArrowDown') {
          e.preventDefault()
          const next = thisListElem.tabIndex + 1
          let nextElem = getSuggestionElem(next)
          if (!nextElem) nextElem = getSuggestionElem(1)

          nextElem?.focus()
          onSelect(nextElem?.textContent ?? undefined)
        } else if (e.key === 'ArrowUp') {
          e.preventDefault()
          const next = thisListElem.tabIndex - 1
          let nextElem = getSuggestionElem(next)
          if (!nextElem) {
            const elems = getSuggestionElems()
            if (elems.length) {
              // We checked the length of the array so safe to assume no undefined
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              nextElem = elems[elems.length - 1]!
            }
          }
          nextElem?.focus()
          onSelect(nextElem?.textContent ?? undefined)
        } else if (e.key === 'Enter') {
          e.preventDefault()
          onSelect(suggestion.text)
        }
      }}
    >
      {suggestion.text}
    </Link>
  )
}

function FacetValue({
  label,
  onSelect,
  active,
  href,
}: {
  label: string
  onSelect: () => void
  active: boolean
  href: UrlObject | string
}) {
  const { onMouseEnter, onMouseLeave } = useHoverSelect(onSelect)

  return (
    <li
      className={`font-semibold ${
        active ? 'text-primary underline' : ''
      } focus:underline focus:outline-none`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link href={href}>{label}</Link>
    </li>
  )
}

export function getSuggestionElem(tabIndex: number) {
  return document.querySelector<HTMLLIElement>(`.Suggestion[tabindex="${tabIndex}"]`)
}

function getSuggestionElems() {
  return document.querySelectorAll<HTMLLIElement>(`.Suggestion`)
}

function Price({ children }: { children: ReactNode }) {
  return <div className="text-sm font-bold text-red-light">{children}</div>
}

export function SuggestionsBox({
  query,
  suggestData,
  show,
  selected,
  onSelectedChange,
  onClickSuggestion,
  filter,
  onFilterChange,
  searchData,
}: {
  query: string
  suggestData: SearchSpringSuggestData
  show: boolean
  selected?: string
  onSelectedChange: (s?: string) => void
  onClickSuggestion: (s: string) => void
  filter: [string, string] | null
  onFilterChange: (filter: [string, string] | null) => void
  searchData?: UseHeaderSearchSpringQuery | null
}) {
  const activeQuery = suggestData.query

  useEffect(() => {
    onSelectedChange(suggestData.suggested?.text)
    onFilterChange(null)
  }, [suggestData, onSelectedChange, onFilterChange])

  return suggestData.suggested && Boolean(query.trim()) && show ? (
    <div className="absolute left-0 top-full w-full overflow-hidden rounded-md bg-ds-neutral shadow-md sm:w-auto lg:mt-4 lg:grid lg:min-h-[32rem] lg:w-[54rem] lg:grid-cols-5">
      <div className="grid grid-cols-2 bg-gray-100 sm:flex sm:flex-row lg:flex-col">
        {[suggestData.suggested, ...suggestData.alternatives].map((a, index) => {
          const isSelected = a.text === selected

          return (
            <SuggestionAnchor
              key={a.text}
              onClick={() => {
                onClickSuggestion(a.text)
              }}
              tabIndex={index + 1}
              suggestion={a}
              onSelect={(selection) => {
                onFilterChange(null)
                onSelectedChange(selection)
              }}
              isSelected={isSelected}
            />
          )
        })}
      </div>
      <div className="md:col-span-4 md:grid md:grid-cols-4">
        <div className="hidden flex-row space-x-4 p-4 text-sm sm:flex md:flex-col md:space-x-0 md:space-y-2">
          {searchData?.facets?.map((f) => {
            return (
              <div className="flex-1 lg:flex-none" key={f.field}>
                <h4 className="mb-2 border-b-2 border-primary-200 font-bold">{f.label}</h4>
                <ul className="space-y-1">
                  {f.values.slice(0, 8).map((v) => {
                    const { value, label, low, high, count } = v

                    if (!count) return null

                    const nextFilter: [string, string] = [
                      `filter.${f.field}`,
                      low && high ? `${low}:${high}` : value,
                    ]

                    const active = filter?.[0] === nextFilter[0] && filter[1] === nextFilter[1]

                    return (
                      <FacetValue
                        key={label}
                        label={label}
                        active={active}
                        onSelect={() => {
                          onFilterChange(nextFilter)
                        }}
                        href={{
                          pathname: routes.search(),
                          hash: `#/${nextFilter.join('.')}`,
                          query: { search_query_adv: activeQuery },
                        }}
                      />
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>
        <div className="col-span-3 p-4">
          <div className="flex h-full flex-col">
            <div className="flex-1">
              <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
                {searchData?.isLoadingSearchResults ? (
                  <>
                    {range(0, 6).map((index) => {
                      let className = ''

                      if (index > 2) {
                        className = `hidden md:block`
                      } else if (index > 1) {
                        className = `hidden sm:block`
                      }

                      return (
                        <div
                          key={index}
                          className={`h-48 rounded-lg bg-gray-100 md:w-auto ${className}`}
                          style={{
                            animation: 'Pulsate 1s linear infinite',
                          }}
                        />
                      )
                    })}
                  </>
                ) : (
                  <>
                    {searchData?.searchResults?.map(
                      (
                        {
                          id,
                          imageUrl,
                          a_custom_url,
                          ss_price_range,
                          name,
                          has_single_variant_pricing,
                          variant_calculated_price,
                          has_valid_price_range,
                        },
                        index
                      ) => {
                        let className = ''

                        if (index > 2) {
                          className = `hidden md:block`
                        } else if (index > 1) {
                          className = `hidden sm:block`
                        }

                        return (
                          <a
                            key={id}
                            href={routes.product(
                              getPathStructureForProduct(a_custom_url)?.headlessSlug
                            )}
                            className={className}
                          >
                            {imageUrl && (
                              <div className="max-w-60 relative h-[8rem] p-2">
                                <Image src={imageUrl} layout="fill" objectFit="contain" />
                              </div>
                            )}
                            <h4 className="line-clamp-2 text-sm font-bold">{unescape(name)}</h4>
                            {!has_single_variant_pricing && has_valid_price_range ? (
                              <Price>
                                {formatStringToCurrency(ss_price_range?.[0])}&nbsp;-&nbsp;
                                {formatStringToCurrency(ss_price_range?.[1])}
                              </Price>
                            ) : has_single_variant_pricing && variant_calculated_price ? (
                              <Price>{formatStringToCurrency(variant_calculated_price)}</Price>
                            ) : null}
                          </a>
                        )
                      }
                    )}
                  </>
                )}
              </div>
            </div>
            {searchData?.totalResults ? (
              <Link
                href={{
                  pathname: routes.search(),
                  query: { search_query_adv: activeQuery },
                  hash: filter?.join('.'),
                }}
                className="mt-4 block text-right text-sm font-semibold uppercase"
              >
                {`See ${searchData.totalResults} results for "${selected}"`}
                <LegacyChevronRightIcon className="ml-2 inline-block h-3 w-3" />
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : null
}
