export function ExclamationIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00008 0.0869141C4.48624 0.0869141 0.826172 3.74698 0.826172 8.26083C0.826172 12.7747 4.48624 16.4347 9.00008 16.4347C13.5139 16.4347 17.174 12.7747 17.174 8.26083C17.1716 3.74698 13.5139 0.0869141 9.00008 0.0869141ZM8.04444 3.42924H9.99939V3.66452L9.79322 9.85437H8.23363L8.04201 3.66452V3.42924H8.04444ZM10.191 12.047C10.191 12.389 10.0722 12.651 9.83688 12.8256C9.60161 13.0002 9.32268 13.09 8.99766 13.09C8.66537 13.09 8.38158 13.0027 8.15116 12.8256C7.92074 12.6485 7.80674 12.389 7.80674 12.047V11.9743C7.80674 11.6298 7.92317 11.3679 8.15116 11.1884C8.38158 11.0065 8.66294 10.9167 8.99766 10.9167C9.32025 10.9167 9.60161 11.0065 9.83688 11.1884C10.0722 11.3703 10.191 11.6323 10.191 11.9743V12.047Z"
        fill="currentColor"
      />
    </svg>
  )
}
