import { Menu, MenuItem } from './FooterMenu'
import { useFooterMenus } from './useFooterMenus'

export function DesktopMenuSection() {
  const footerMenus = useFooterMenus()

  return (
    <>
      {footerMenus.map(({ className, menu, menuItems }) => (
        <div
          key={menu}
          className={`col-span-4 hidden cursor-pointer md:mb-10 md:block lg:px-4 ${className}`}
        >
          <Menu className="space-y-4">
            <MenuItem>
              <h5 className="uppercase text-ds-neutral-100">{menu}</h5>
            </MenuItem>
            {menuItems.map(({ title, ...rest }) => (
              <MenuItem key={title} {...rest}>
                {title}
              </MenuItem>
            ))}
          </Menu>
        </div>
      ))}
    </>
  )
}
