import CarewellHeartOutlineLogo from '#public/icon/carewell-heart.svg'
import Link from 'next/link'
import routes from '../../../../lib/routes'

export default function JoinOurTeam() {
  return (
    <div className="flex items-center space-x-4 text-ds-neutral">
      <CarewellHeartOutlineLogo className="w-7 *:stroke-ds-neutral" />
      <Link href={routes.careers()} className="flex cursor-pointer flex-col tracking-wide">
        <span className="underline">Join Our Team</span>
        <span>Check out careers</span>
      </Link>
    </div>
  )
}
