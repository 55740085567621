import type { CSSProperties } from 'react'

export function BurgerMenuIcon({
  className,
  style,
}: {
  className?: string
  style?: CSSProperties
}) {
  return (
    <svg
      className={className}
      style={style}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.625 14.5H25.375"
        stroke="currentColor"
        strokeWidth="2.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.625 7.25H25.375"
        stroke="currentColor"
        strokeWidth="2.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.625 21.75H25.375"
        stroke="currentColor"
        strokeWidth="2.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
