import { useEffect } from 'react'
import { useUI } from '../components/global/ui-context'

export function usePreventBackgroundScrollOnModal(modalOpen: boolean) {
  const { lockBackgroundScroll, unlockBackgroundScroll } = useUI()

  useEffect(() => {
    if (modalOpen) {
      lockBackgroundScroll()
    } else {
      unlockBackgroundScroll()
    }

    return () => {
      if (modalOpen) {
        unlockBackgroundScroll()
      }
    }
  }, [lockBackgroundScroll, modalOpen, unlockBackgroundScroll])
}
