export function ChevronIcon({ className }: { className: string }) {
  return (
    <svg className={className} viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.28782 7.00521L2.42106 12.7907C2.13811 13.0698 1.67938 13.0698 1.39646 12.7907L0.712203 12.1159C0.429739 11.8374 0.429196 11.3859 0.710996 11.1067L5.3605 6.49998L0.710996 1.89328C0.429196 1.61407 0.429739 1.16262 0.712203 0.884061L1.39646 0.209273C1.67941 -0.0697577 2.13814 -0.0697577 2.42106 0.209273L8.28779 5.99479C8.57073 6.2738 8.57073 6.72617 8.28782 7.00521Z"
        fill="currentColor"
      />
    </svg>
  )
}
