import FacebookIcon from '#public/icon/facebook.svg'
import InstagramIcon from '#public/icon/instagram.svg'
import LinkedInIcon from '#public/icon/linkedin.svg'
import YouTubeIcon from '#public/icon/youtube.svg'
import COMPANY_INFO from '../../../../lib/constants/COMPANY_INFO'

const SOCIAL_LINKS = [
  {
    children: <FacebookIcon />,
    href: COMPANY_INFO.socialLinks.facebook,
    title: 'Facebook',
  },
  {
    children: <InstagramIcon />,
    href: COMPANY_INFO.socialLinks.instagram,
    title: 'Instagram',
  },
  {
    children: <LinkedInIcon />,
    href: COMPANY_INFO.socialLinks.linkedin,
    title: 'LinkedIn',
  },
  {
    children: <YouTubeIcon />,
    href: COMPANY_INFO.socialLinks.youtube,
    title: 'YouTube',
  },
]

export default function FollowUsSection({ className }: { className?: string }) {
  return (
    <div className={className}>
      {SOCIAL_LINKS.map(({ children, href, title }) => (
        <div key={title} className="mr-6 lg:mr-0">
          <a
            className="mr-6 w-5 text-ds-neutral"
            href={href}
            aria-label={`Follow Us on ${title}`}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        </div>
      ))}
    </div>
  )
}
