import { useRef } from 'react'

export function useHoverSelect(onSelect: () => void) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const onMouseEnter = (e) => {
    timeoutRef.current = setTimeout(() => {
      onSelect()
      e.target?.focus()
      timeoutRef.current = null
    }, 500)
  }

  const onMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }
  return { onMouseEnter, onMouseLeave }
}
