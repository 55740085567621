import { useKustomerChatStatus } from 'components/global/kustomer-chat-context'
import ChatBubbleIcon from 'public/icon/chat-bubble.svg'

export default function CSChatWiget() {
  const { initialized, openChat, statusText, unavailable } = useKustomerChatStatus()
  const disabled = !initialized || unavailable

  return (
    <div className="flex items-center space-x-4 text-ds-neutral">
      <ChatBubbleIcon className="w-8 *:stroke-ds-neutral" />
      <button
        type="button"
        disabled={!initialized}
        className={`flex flex-col text-left ${disabled && 'cursor-not-allowed'}`}
        onClick={openChat}
      >
        <p className="tracking-wide underline">Live Care Advice</p>
        <p>{statusText}</p>
      </button>
    </div>
  )
}
