import { useRef } from 'react'
import type { BigCommerceGQLCategoryTree3LevelsDeep } from '../lib/serverOnly/api/bigcommerce/graphql/catalog'
import useSearchSpringQuery from './pageType/category/useSearchSpringQuery'

type Facets = NonNullable<ReturnType<typeof useSearchSpringQuery>['data']>['facets']

/**
 * Variant of useSearchSpringQuery specifically for the header search to avoid facets that should not be displayed
 * within the header search results
 */
export default function useHeaderSearchSpringQuery({
  selected,
  filter,
  enabled,
  categoryTree,
}: {
  selected: string | undefined
  filter: [string, string] | undefined
  enabled: boolean
  categoryTree: BigCommerceGQLCategoryTree3LevelsDeep
}) {
  const { data: searchData, isValidating: isLoadingSearchResults } = useSearchSpringQuery({
    query: selected,
    resultsPerPage: 6,
    keepPreviousData: false,
    filters: filter ? [filter] : undefined,
    enabled,
    autoComplete: true,
    categoryTree,
  })

  const searchResults = searchData?.results

  const ref = useRef<Record<string, Facets | undefined>>({})

  if (searchData && selected && !ref.current[selected]) {
    ref.current[selected] = searchData.facets
      .filter(
        (f) =>
          f.values.length > 1 &&
          f.type !== 'slider' &&
          f.field !== 'categories_hierarchy' &&
          Boolean(f.values.filter((fv) => fv.count).length)
      )
      .slice(0, 3)
  }

  return {
    isLoadingSearchResults,
    searchResults,
    facets: selected ? ref.current[selected] : undefined,
    totalResults: searchData?.pagination?.totalResults ?? 0,
  }
}

export type UseHeaderSearchSpringQuery = ReturnType<typeof useHeaderSearchSpringQuery>
