import COMPANY_INFO from '../constants/COMPANY_INFO'

const DAYS = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}

const TZ = COMPANY_INFO.timezone.displayValue

function formatHour(hour: number) {
  if (hour === 0) return `12am`
  if (hour === 12) return `12pm`
  if (hour > 12) return `${hour - 12}pm`
  return `${hour}am`
}

export function getCompanyHoursDayRange(hours: { days: number[] }) {
  const startDay = hours.days[0]
  const endDay = hours.days[hours.days.length - 1]

  if (startDay === undefined || endDay === undefined) throw new Error('Must pass days')

  return `${DAYS[startDay]} - ${DAYS[endDay]}`
}

export function getCompanyHoursRange(hours: { startHour: number; endHour: number }) {
  const { startHour, endHour } = hours

  if (startHour < 1 && endHour > 23) {
    return '24 hour service'
  }

  return `${formatHour(startHour)} - ${formatHour(endHour)} ${TZ}`
}
