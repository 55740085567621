import COMPANY_INFO from '../constants/COMPANY_INFO'

/**
 * Returns date in configured company timezone
 */
function getDateInTimeZone(now: Date) {
  return new Date(now.toLocaleString('en-US', { timeZone: COMPANY_INFO.timezone.value }))
}

function isWithinHourRange(range: typeof COMPANY_INFO.hours, now: Date) {
  const date = getDateInTimeZone(now)

  const hour = date.getHours()
  const day = date.getDay()
  const companyHours = range.find(
    (i) => i.days.includes(day) && hour >= i.startHour && hour < i.endHour
  )
  return !!companyHours
}

export function isCompanyHours(now = new Date()) {
  return isWithinHourRange(COMPANY_INFO.hours, now)
}

export function isCompanyCallHours(now = new Date()) {
  return isWithinHourRange(COMPANY_INFO.callHours, now)
}

export function isFullTimeHours() {
  const firstEntry = COMPANY_INFO.hours[0]

  if (!firstEntry) return false

  return firstEntry.startHour === 0 && firstEntry.endHour === 24 && firstEntry.days.length >= 7
}
