import { useCallback } from 'react'
import type { InferProcedureOutput, Procedures } from '../../../../server/routers/_app'
import useDataLayerPush from './useDataLayerPush'

export default function useGTMUserEvents() {
  const push = useDataLayerPush()

  return {
    login: useCallback(
      ({
        method,
        customer,
      }: {
        method: string
        customer: InferProcedureOutput<Procedures['customer']['getCustomer']>
      }) => {
        // Clear the previous ecommerce object
        push({
          mainData: {
            method: null,
          },
        })
        push({
          mainData: {
            method,
          },
          customer,
          event: {
            name: 'login',
          },
        })
      },
      [push]
    ),
    signup: useCallback(
      ({
        method,
        customer,
      }: {
        method: string
        customer: InferProcedureOutput<Procedures['customer']['getCustomer']>
      }) => {
        // Clear the previous ecommerce object
        push({
          mainData: {
            method: null,
          },
        })
        push({
          mainData: {
            method,
          },
          event: {
            name: 'sign_up',
          },
          customer,
        })
      },
      [push]
    ),
    logout: useCallback(
      ({ customer }: { customer: InferProcedureOutput<Procedures['customer']['getCustomer']> }) => {
        push({
          mainData: {},
          customer,
          event: {
            name: 'logout',
          },
        })
      },
      [push]
    ),
  }
}
