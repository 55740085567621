import clsx from 'clsx'
import type { ReactNode } from 'react'
import { ExclamationIcon } from '../icons/ExclamationIcon'
import { InfoIcon } from '../icons/InfoIcon'

export type NotificationType = 'error' | 'warning' | 'success'

export type NotificationProps = {
  children: ReactNode
  title?: string
  className?: string
  type?: NotificationType
}

export function Notification({ children, className, title, type }: NotificationProps) {
  const isError = type === 'error'

  return (
    <div
      className={clsx(
        'flex space-x-4 rounded-md border-l-8 border-solid bg-opacity-10 p-4',
        {
          'border-pepper bg-pepper text-pepper': isError,
          'border-mustard bg-mustard text-mustard': type === 'warning',
          'border-green bg-green text-green': type === 'success',
        },
        className
      )}
    >
      <div className={clsx(title && 'mt-0.5')}>
        {isError ? <ExclamationIcon className="h-5 w-5" /> : <InfoIcon className="h-5 w-5" />}
      </div>
      <div className="space-y-2">
        {title && <h4 className="font-medium leading-none">{title}</h4>}
        <p className="flex-1 text-sm leading-tight text-black" role="alert">
          {children}
        </p>
      </div>
    </div>
  )
}
