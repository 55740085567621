import PhoneIcon from '#public/icon/phone.svg'
import COMPANY_INFO from '../../../../lib/constants/COMPANY_INFO'
import CSAvailability from './CSAvailability'

export default function CareSpecialist() {
  return (
    <div className="flex items-center space-x-4 text-ds-neutral">
      <PhoneIcon className="w-6 *:stroke-ds-neutral" />
      <a className="flex flex-col space-y-0 font-semibold" href={`tel:${COMPANY_INFO.phone.value}`}>
        <p className="tracking-wide underline">
          Speak to a Care Specialist&nbsp;
          <CSAvailability />
        </p>
        <p lang="es">
          <strong>{COMPANY_INFO.phone.displayValue}</strong>
        </p>
      </a>
    </div>
  )
}
