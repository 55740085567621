import { authenticatedMyCarewellMenu } from '#/13/(main)/layout/Footer/footerMenus'
import { useCallback, useMemo } from 'react'
import routes from '../lib/routes'
import useCustomerQuery from './useCustomerQuery'

export default function useMyCarewellFooterMenu() {
  const openAuthModal = useCallback((...args) => {
    // eslint-disable-next-line no-console
    console.error('openAuthModal not yet implemented!', ...args)
  }, [])

  // TODO: This can be server side using the cookie
  const { data: customer } = useCustomerQuery()

  const customerLoggedin = !!customer

  return useMemo(
    () =>
      !customerLoggedin
        ? {
            ...authenticatedMyCarewellMenu,
            menuItems: [
              {
                onClick: () =>
                  openAuthModal({
                    redirectToPage: 'My Account',
                    redirectToPageURL: routes.account.index(),
                  }),
                title: 'Sign In',
              },
              {
                href: routes.orderLookup(),
                title: 'Track My Order',
              },
              {
                onClick: () =>
                  openAuthModal({
                    redirectToPage: 'Manage My Autoship',
                    redirectToPageURL: routes.account.autoships(),
                  }),
                title: 'Manage My Autoship',
              },
              {
                onClick: () =>
                  openAuthModal({
                    redirectToPage: 'Easy Reorder',
                    redirectToPageURL: routes.account.buyAgain(),
                  }),
                title: 'Easy Reorder',
              },
              {
                onClick: () =>
                  openAuthModal({
                    redirectToPage: 'Order History',
                    redirectToPageURL: routes.account.orders(),
                  }),
                title: 'Order History',
              },
              {
                onClick: () =>
                  openAuthModal({
                    redirectToPage: 'Account Settings',
                    redirectToPageURL: routes.account.settings(),
                  }),
                title: 'Account Settings',
              },
            ],
          }
        : authenticatedMyCarewellMenu,
    [customerLoggedin, openAuthModal]
  )
}
