import clsx from 'clsx'
import TimesIcon from '../icons/TimesIcon'
import { BurgerMenuIcon } from './BurgerMenuIcon'

export type MenuButtonProps = { open?: boolean; className?: string; onClick?: () => void }

export default function MenuButton({ open = false, className, onClick }: MenuButtonProps) {
  return (
    <button
      type="button"
      className={clsx(
        'focus:outline-none flex w-10 flex-col items-center rounded text-xs font-bold text-ds-neutral focus:ring-2 focus:ring-secondary',
        className
      )}
      onClick={onClick}
    >
      {open ? (
        <TimesIcon
          style={{
            width: '1.8125rem',
          }}
        />
      ) : (
        <BurgerMenuIcon
          style={{
            width: '1.8125rem',
          }}
        />
      )}
      <span
        className="uppercase"
        style={{
          fontSize: '0.608125rem',
          lineHeight: '11.68px',
        }}
      >
        {open ? 'Close' : 'Menu'}
      </span>
    </button>
  )
}
