import { useRouter } from 'next/router'
import type { FormEventHandler } from 'react'
import { useEffect } from 'react'
import type SharedHeaderProps from '../components/global/header/Header/SharedHeaderProps'
import { useUI } from '../components/global/ui-context'
import routes from '../lib/routes'
import type { SearchSpringSuggestData } from './pageType/category/useSearchspringSuggest'
import useAuthStateQuery from './useAuthStateQuery'
import useCartQuery from './useCartQuery'
import useLogoutMutation from './useLogoutMutation'

export type UseHydrateHeaderProps = {
  onSearchQueryChange: (query: string) => void
  searchQuery: string
  suggestData: SearchSpringSuggestData | null | undefined
}

export function useHydrateHeader({
  onSearchQueryChange,
  searchQuery,
  suggestData,
}: UseHydrateHeaderProps) {
  const { data: customer } = useAuthStateQuery()
  const signedIn = Boolean(customer?.id)
  const router = useRouter()
  const { mutate: onLogout } = useLogoutMutation()

  const {
    openSearchMenu: onOpenSearchMenu,
    closeSearchMenu: onCloseSearchMenu,
    closeSearchSuggestions: onCloseSearchSuggestions,
    openSearchSuggestions: onOpenSearchSuggestions,
    displaySearchSuggestions,
    toggleMinicart: onCartClick,
    closeMinicart,
    displayMiniCart,
  } = useUI()

  const { data: cartData, isLoading: cartLoading } = useCartQuery()

  useEffect(() => {
    const onRouteChange = () => {
      onCloseSearchMenu()
      onSearchQueryChange('')
    }

    router.events.on('routeChangeComplete', onRouteChange)

    return () => {
      router.events.off('routeChangeComplete', onRouteChange)
    }
  }, [onCloseSearchMenu, onSearchQueryChange, router])

  const customerFirstName = customer?.firstName

  const onHideCart = () => {
    closeMinicart()
  }

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    onCloseSearchMenu()
    router.push({
      pathname: routes.search(),
      query: suggestData?.['corrected-query']
        ? {
            search_query_adv: suggestData?.['corrected-query'],
            originalQuery: searchQuery,
          }
        : {
            search_query_adv: searchQuery,
          },
    })
  }

  return {
    signedIn,
    onLogout,
    onOpenSearchMenu,
    onCloseSearchMenu,
    onCloseSearchSuggestions,
    onOpenSearchSuggestions,
    displaySearchSuggestions,
    onCartClick,
    displayMiniCart,
    cartLoading,
    cartData,
    customerFirstName,
    onHideCart,
    onSubmit,
    onSearchQueryChange,
    searchQuery,
  }
}

export type HydratedHeaderProps = Omit<
  SharedHeaderProps,
  keyof ReturnType<typeof useHydrateHeader>
> &
  UseHydrateHeaderProps
