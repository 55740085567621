import CarewellHeartLogo from '#public/icon/carewell-logo.svg'
import { Fragment } from 'react'
import COMPANY_INFO from '../../../../lib/constants/COMPANY_INFO'
import routes from '../../../../lib/routes'
import { Menu, MenuItem } from './FooterMenu'

const COMPLIANCE_LINKS = [
  { href: routes.privacyPolicy(), title: 'Privacy' },
  { href: routes.termsAndConditions(), title: 'Terms' },
  { href: routes.webAccessibilityStatement(), title: 'Accessibility' },
  { href: routes.ccpa(), title: 'CCPA' },
]

export default function LegalSection({ className }: { className: string }) {
  return (
    <Menu className={className}>
      <MenuItem className="w-full text-sm lg:w-auto">
        <CarewellHeartLogo className="mr-1 inline w-3 align-middle" />
        &copy; {new Date().getFullYear()} {COMPANY_INFO.name}
      </MenuItem>
      {COMPLIANCE_LINKS.map(({ href, title }, index) => (
        <Fragment key={title}>
          <MenuItem childClassName="text-sm underline text-ds-neutral-100" href={href}>
            {title}
          </MenuItem>
          {index !== COMPLIANCE_LINKS.length - 1 && (
            <MenuItem className="px-3 text-ds-neutral-100 lg:px-0">|</MenuItem>
          )}
        </Fragment>
      ))}
    </Menu>
  )
}
