import { Button } from 'components/global/button/Button'
import { Notification } from 'components/global/notification/Notification'
import useKlaviyoSubscribe from 'hooks/useKlaviyoSubscribe'
import React, { useState } from 'react'

const FooterSubscribe: React.FC = () => {
  const [email, setEmail] = useState('')
  const { subscribe, loading, error, success } = useKlaviyoSubscribe()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await subscribe(email)
  }

  return (
    <div className="space-y-3">
      <h4 className="font-bold">Join our email list.</h4>
      <p className="max-w-xl lg:max-w-full">
        We’ll send you expert product recommendations, helpful articles, & caregiver stories.
      </p>
      <form className="flex flex-row items-center" onSubmit={handleSubmit}>
        <input
          type="email"
          className="border-px h-border-solid border-coolGray-400 mr-2 box-border block h-12 w-2/3 rounded-md px-4 py-2 text-black md:w-3/4 lg:w-2/3"
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          className="whitespace-nowrap"
          variant="outline"
          size="sm"
          type="submit"
          loading={loading}
        >
          Sign up
        </Button>
      </form>
      {!!error && (
        <div className="rounded-md bg-neutral">
          <Notification title="Oops, that didn’t work." type="error">
            Please double check your email address is correct and try again.
          </Notification>
        </div>
      )}
      {success && (
        <div className="rounded-md bg-neutral">
          <Notification title="You’re in!" type="success">
            Thanks for joining, we’re happy you’re here.
          </Notification>
        </div>
      )}
    </div>
  )
}

export default FooterSubscribe
