import CareSpecialist from './CareSpecialist'
import ChatWidget from './ChatWidget'
import SectionDivider from './SectionDivider'

export default function MobileHelpSection() {
  return (
    <div className="col-span-12 lg:hidden">
      <SectionDivider className="my-8" />
      <div className="flex flex-col space-y-8 text-sm">
        <ChatWidget />
        <CareSpecialist />
      </div>
      <SectionDivider className="my-8" />
    </div>
  )
}
