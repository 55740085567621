import routes from '../../../../lib/routes'

export enum FooterMenu {
  ABOUT_US = 'About Us',
  HELP = 'Help',
  MY_CAREWELL = 'My Carewell',
}

export type FooterMenuProps = {
  className: string
  menu: FooterMenu
  menuItems: Array<{ href?: string; onClick?: () => void; title: string }>
}

export const aboutUsMenu: FooterMenuProps = {
  className: 'space-y-6 lg:col-span-3 lg:ml-20',
  menu: FooterMenu.ABOUT_US,
  menuItems: [
    { href: routes.ourStory(), title: 'Our Story' },
    { href: routes.careers(), title: 'Careers' },
    { href: routes.deals(), title: 'Deals' },
    { href: routes.ambassadorProgram(), title: 'Ambassador Program' },
    { href: routes.newsroom(), title: 'Press' },
  ],
}

export const helpMenu: FooterMenuProps = {
  className: 'ml-8 lg:ml-0 lg:col-span-2 lg:px-4',
  menu: FooterMenu.HELP,
  menuItems: [
    { href: routes.faq(), title: 'FAQ' },
    { href: routes.returns(), title: 'Returns' },
    { href: routes.autoship(), title: 'Autoship' },
    { href: routes.blog(), title: 'Blog' },
    { href: routes.bulkOrders(), title: 'Bulk Orders' },
  ],
}

export const authenticatedMyCarewellMenu: FooterMenuProps = {
  className: 'lg:col-span-2 lg:px-4',
  menu: FooterMenu.MY_CAREWELL,
  menuItems: [
    { href: routes.account.index(), title: 'My Account' },
    { href: routes.account.orders(), title: 'Track My Order' },
    { href: routes.account.autoships(), title: 'Manage My Autoship' },
    { href: routes.account.orders(), title: 'Easy Reorder' },
    { href: routes.account.orders(), title: 'Order History' },
    { href: routes.account.settings(), title: 'Account Settings' },
  ],
}
