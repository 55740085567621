import useGTMUserEvents from 'lib/clientOnly/analytics/gtm/useGTMUserEvents'
import { trpc } from '../lib/utils/trpc'

export default function useLogoutMutation() {
  const { logout } = useGTMUserEvents()

  const utils = trpc.useUtils()

  return trpc.auth.logout.useMutation({
    onSuccess: (data) => {
      // Reset all queries to take into account new auth state
      utils.invalidate()
      // Force refetch customer
      utils.customer.getCustomer.refetch()
      // Force fetch cart
      utils.cart.getCart.refetch()
      logout({ customer: data.customer })
    },
  })
}
