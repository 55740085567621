import Link from 'next/link'
import MY_ACCOUNT_TABS from '../../../../../lib/constants/MY_ACCOUNT_TABS'
import { LegacyUserIcon } from '../../../legacy/icons/LegacyUserIcon'
import { useUI } from '../../../ui-context'

export default function MobileAccountButton({ loggedIn }: { loggedIn: boolean }) {
  const { openAuthModal } = useUI()

  const className =
    'text-ds-neutral font-medium focus:ring-2 focus:ring-secondary focus:outline-none rounded flex flex-row items-center space-x-2'

  if (loggedIn) {
    return (
      <Link
        href={MY_ACCOUNT_TABS.overview.path}
        id="account-button-mobile"
        aria-label="My Account"
        className={className}
      >
        <p className="xs:not-sr-only sr-only text-left leading-none">Account</p>
        <LegacyUserIcon className="w-5" />
      </Link>
    )
  }

  return (
    <button
      type="button"
      className={className}
      id="account-button-mobile"
      onClick={() => openAuthModal()}
      aria-haspopup={true}
    >
      <span className="xs:not-sr-only sr-only text-left">Sign In</span>
      <LegacyUserIcon className="w-5" />
    </button>
  )
}
