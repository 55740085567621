import FollowUsSection from './FollowUsSection'
import LegalSection from './LegalSection'

export default function MobileComplianceSection() {
  return (
    <>
      <FollowUsSection className="col-span-12 mb-4 flex items-end lg:hidden" />
      <LegalSection className="col-span-12 flex flex-wrap items-center space-y-2 lg:hidden" />
    </>
  )
}
