'use client'

import ChevronRightLight from '#public/icon/chevron-right-light.svg'
import { Menu, MenuItem } from './FooterMenu'
import type { FooterMenuProps } from './footerMenus'
import { FooterMenu } from './footerMenus'
import { useFooterMenus } from './useFooterMenus'

function MobileMenu({ menu, menuItems }: FooterMenuProps) {
  return (
    <details
      className="group relative min-w-[9.375rem] list-none"
      open={menu === FooterMenu.MY_CAREWELL}
      data-testid="mobile-menu"
    >
      <summary className="relative cursor-pointer" data-testid="mobile-menu-summary">
        <span className="inline-block py-2 pr-5">{menu}</span>
        <span
          className={`transition-cubic-bezier-[0.4,0,0.2,1] inline-block transform-none bg-contain bg-no-repeat align-middle transition-transform duration-150 group-open:rotate-90`}
        >
          <ChevronRightLight data-testid="chevron-icon" />
        </span>
      </summary>
      <Menu className="space-x-4 space-y-4">
        {menuItems.map(({ title, ...rest }) => (
          <MenuItem
            key={title}
            childClassName="inline-block p-3 min-w-[9.375rem]"
            className="ml-4"
            {...rest}
          >
            {title}
          </MenuItem>
        ))}
      </Menu>
    </details>
  )
}

export default function MobileMenuSection() {
  const footerMenus = useFooterMenus()
  return (
    <div className="space-y-4 pb-6 md:hidden">
      {footerMenus.map((props) => (
        <MobileMenu key={props.menu} {...props} />
      ))}
    </div>
  )
}
