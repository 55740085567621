import routes from '../routes'
import COMPANY_INFO from './COMPANY_INFO'

const MY_ACCOUNT_TABS = {
  overview: {
    pageTitle: `Account`,
    tabTitle: 'Overview',
    path: routes.account.index(),
    legacyPath: routes.bigCommerceAccount.index(),
  },
  order_status: {
    pageTitle: `My ${COMPANY_INFO.name} Orders`,
    tabTitle: 'Order History',
    path: routes.account.orders(),
    legacyPath: routes.bigCommerceAccount.orders(),
  },
  buy_again: {
    pageTitle: `Buy Again`,
    tabTitle: 'Buy Again',
    path: routes.account.buyAgain(),
    legacyPath: routes.account.buyAgain(),
  },
  auto_ship: {
    pageTitle: 'My Autoship',
    tabTitle: 'My Autoship',
    path: routes.account.autoships(),
    legacyPath: routes.bigCommerceAccount.autoships(),
  },
  address_book: {
    pageTitle: 'Address Book',
    tabTitle: 'Addresses',
    path: routes.account.addresses.index(),
    legacyPath: routes.bigCommerceAccount.addresses.index(),
  },
  payment_methods: {
    pageTitle: 'Payment Methods',
    tabTitle: 'Payment Methods',
    path: routes.account.payments.index(),
    legacyPath: routes.bigCommerceAccount.payments(),
  },
  account_details: {
    pageTitle: 'Account Settings',
    tabTitle: 'Account Settings',
    path: routes.account.settings(),
    legacyPath: routes.bigCommerceAccount.settings(),
  },
}

export default MY_ACCOUNT_TABS
