import { useCallback, useMemo } from 'react'
import { getSearchspringSiteId } from '../../../lib/integrations/searchspring'
import { useApiQuery } from '../../useApiQuery'
import useDebounce from '../../useDebounce'

type CompleteSuggestion = {
  text: string
  type: 'completed'
  completed: Array<{
    token: string
    query: string
    type: string
  }>
  source: string
}

type IncompleteSuggestion = {
  text: string
  type: undefined
  popularity: number
}

export type Suggestion = CompleteSuggestion | IncompleteSuggestion

export type SearchSpringSuggestData = {
  query: string
  'corrected-query'?: string
  suggested: CompleteSuggestion | null
  alternatives: Suggestion[]
}

function useSearchSpringSuggestUrl(query: string) {
  return useMemo(() => {
    if (query.trim().length) {
      const siteId = getSearchspringSiteId()
      return `https://suggest-cache.searchspring.net/api/suggest/query?lang=en&pubId=${siteId}&query=${query}`
    }
    return null
  }, [query])
}

export default function useSearchspringSuggest(query: string) {
  const [debouncedQuery, { isWaiting }] = useDebounce(query)
  const url = useSearchSpringSuggestUrl(debouncedQuery)

  const { data, isValidating, error } = useApiQuery<SearchSpringSuggestData>(url, {
    parser: useCallback((data) => data, []),
    revalidateOnFocus: false,
    keepPreviousData: true,
  })

  return {
    data,
    isLoading: isValidating || isWaiting,
    error,
  }
}
