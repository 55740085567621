import { trpc } from '../lib/utils/trpc'
import type { ReactQueryProcedureOptions } from '../server/routers/_app'

export default function useAuthStateQuery(
  options: ReactQueryProcedureOptions['auth']['state']['options'] = {}
) {
  return trpc.auth.state.useQuery(undefined, {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
    ...(options as any),
  })
}

export type AuthStateQueryData = NonNullable<ReturnType<typeof useAuthStateQuery>['data']>
