import { scrollToTop } from '../../../../lib/utils/scrollToTop'
import StandardIcon from '../../icons/StandardIcon'

export default function ScrollToTop() {
  return (
    <button
      className="border-t border-[#e9e9e9] pb-6 pt-8 text-center text-xs font-bold leading-none text-[#71739a] print:hidden md:hidden"
      onClick={scrollToTop}
    >
      <StandardIcon icon="chevron-up" alt="" width={18} height={18} />
      <span className="block uppercase">Top of Page</span>
    </button>
  )
}
