import ClipboardIcon from '#public/icon/clipboard.svg'
import Link from 'next/link'
import featureFlags from '../../../../lib/featureFlags'
import routes from '../../../../lib/routes'

export default function EnrollInMedicare() {
  if (!featureFlags.healthpilot) {
    return null
  }

  return (
    <div className="flex items-center space-x-4 text-ds-neutral">
      <ClipboardIcon className="w-7 *:stroke-ds-neutral" />
      <Link
        className="flex cursor-pointer flex-col tracking-wide"
        href={routes.medicare()}
        passHref
      >
        <p className="underline">Enroll in Medicare</p>
      </Link>
    </div>
  )
}
