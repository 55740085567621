export default {
  quickLinkBtnClick: 'quickLinkBtnClick',
  quickLinkErrorMessage: 'quickLinkErrorMessage',
  quickLinkSentMessage: 'quickLinkSentMessage',
  forgotPasswordBtnClick: 'forgotPasswordBtnClick',
  loginBtnClick: 'loginBtnClick',
  loginErrorMessage: 'loginErrorMessage',
  createAccountBtnClick: 'createAccountBtnClick',
  createAccountErrorMessage: 'createAccountErrorMessage',
  checkoutBtnClick: 'checkoutBtnClick',
  cartAddBtnClick: 'cartAddBtnClick',
  cartAddErrorMessage: 'cartAddErrorMessage',
  errorBoundaryMessage: 'errorBoundaryMessage',
  checkoutRedirectFail: 'checkoutRedirectFail',
  customerId: 'customerId',
  customerEmail: 'customerEmail',
  donationToggle: 'donationToggle',
}
