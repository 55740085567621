import clsx from 'clsx'
import Link from 'next/link'
import type { ReactNode } from 'react'

export function Menu({ children, className }: { children: ReactNode; className?: string }) {
  return <ul className={className}>{children}</ul>
}

export function MenuItem({
  childClassName,
  children,
  className,
  href,
  onClick,
}: {
  childClassName?: string
  children: ReactNode
  className?: string
  href?: string
  onClick?: () => void
}) {
  return (
    <li className={className}>
      {onClick ? (
        <button
          type="button"
          className={clsx('text-left hover:underline', childClassName)}
          onClick={onClick}
        >
          {children}
        </button>
      ) : href ? (
        <Link href={href} prefetch={false} className={clsx('hover:underline', childClassName)}>
          {children}
        </Link>
      ) : (
        children
      )}
    </li>
  )
}
