import { Component } from 'react'
import ANALYTICS_ACTIONS from '../../lib/constants/ANALYTICS_ACTIONS'

function writeToLogger(error, errorInfo) {
  error.message = `ErrorBoundary: ${error.message}`
  console.error(errorInfo, { error })
}

export default class ErrorBoundary extends Component {
  public state
  public props
  constructor(props) {
    super(props)
    this.props = props
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    writeToLogger(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className={
            'container flex flex-col items-center justify-items-center p-5 px-6 sm:text-2xl  '
          }
        >
          <div className={'max-w-sm text-center sm:max-w-lg sm:border-2 sm:border-solid sm:p-12'}>
            <p className={'text-center font-bold'}>
              <span className={'text-4xl'}>Whoops!</span> <br /> Our website is having a hiccup.
            </p>
            <p className={'text-center text-sm '}>
              Our team logged the error and will work on fixing it.
            </p>
            <button
              className={
                'm-2 mt-4 rounded-md border-2 border-solid border-yellow-dark p-3 text-base font-bold uppercase'
              }
              onClick={() => {
                window?.location?.reload()
              }}
            >
              Try Shopping Again
            </button>
            <p className={'p-2 text-center text-sm'}>
              If you are unable to shop please call our excellent <b>Customer Care</b> during
              business hours and they will place the order for you!
            </p>
            <div
              className={' text-gray-400'}
              style={{
                fontSize: '9px',
                lineHeight: '1',
                margin: '10px 0',
                width: '100%',
                maxHeight: '100px',
                overflowY: 'scroll',
              }}
            >
              <div className="hidden" data-cw-track={ANALYTICS_ACTIONS.errorBoundaryMessage}>
                {this.state?.error?.message}
              </div>
              {this.state?.error?.message} - {this.state?.error?.stack}
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
