export function InfoIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.10149 14.9855C12.2392 14.9855 15.5942 11.6305 15.5942 7.49278C15.5942 3.35509 12.2392 2.76566e-05 8.10149 2.76566e-05C3.9638 2.76566e-05 0.608731 3.35509 0.608731 7.49278C0.610954 11.6305 3.9638 14.9855 8.10149 14.9855ZM8.97749 11.9217H7.18546V11.7061L7.37444 6.03203L8.80407 6.03203L8.97972 11.7061V11.9217H8.97749ZM7.00981 4.0221C7.00981 3.70861 7.11876 3.46848 7.33442 3.3084C7.55009 3.14832 7.80578 3.06605 8.10371 3.06605C8.40831 3.06605 8.66844 3.1461 8.87966 3.3084C9.09088 3.47071 9.19538 3.70861 9.19538 4.0221V4.0888C9.19538 4.40452 9.08866 4.64465 8.87966 4.80918C8.66844 4.97593 8.41053 5.05819 8.10371 5.05819C7.808 5.05819 7.55009 4.97593 7.33442 4.80918C7.11876 4.64242 7.00981 4.4023 7.00981 4.0888V4.0221Z"
        fill="currentColor"
      />
    </svg>
  )
}
