import { Fragment } from 'react'
import COMPANY_INFO from '../../../../lib/constants/COMPANY_INFO'
import { getCompanyHoursDayRange, getCompanyHoursRange } from '../../../../lib/utils/companyInfo'
import { isFullTimeHours } from '../../../../lib/utils/isCompanyHours'

export default function CSAvailability() {
  return (
    <span>
      {isFullTimeHours()
        ? '24/7'
        : COMPANY_INFO.hours.map((entry) => (
            <Fragment key={`${entry}`}>
              {getCompanyHoursDayRange(entry)} ({getCompanyHoursRange(entry)})
            </Fragment>
          ))}
    </span>
  )
}
